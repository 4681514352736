import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface ISEOProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: Array<any>;
  keywords?: string;
  pathname?: string;
  cardImage?: string;
}

const DefaultSEOProps = {
  lang: 'en',
  meta: [],
  description: undefined,
  pathname: undefined,
  keywords: undefined,
  cardImage: undefined,
};

function SEO(props: ISEOProps) {
  const { description, lang, meta, title, pathname, keywords, cardImage } =
    props;
  // const test:  = {};

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            cardImage
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `,
  );

  const metaImage = cardImage || site.siteMetadata.cardImage;
  const metaKeywords = keywords || site.siteMetadata.keywords;
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <Helmet
      title={metaTitle}
      htmlAttributes={{
        lang,
      }}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image',
          content: metaImage,
        },
        {
          name: 'twitter:creator',
          content: '@befreestudios',
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = DefaultSEOProps;

export default SEO;
