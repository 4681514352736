export const defaultPageProps = {
  path: '',
  uri: '',
  location: undefined,
  navigate: undefined,
  children: undefined,
  params: undefined,
  pageResources: {
    component: undefined,
    json: {
      data: undefined,
      pageContext: undefined,
    },
    page: {
      componentChunkName: '',
      path: '',
      webpackCompilationHash: '',
      matchPath: '',
    },
  },
  data: undefined,
  serverData: undefined,
  pageContext: undefined,
  seoTitle:
    'Website Development, User Experience Design, and Digital Strategy - Be Free Studios',
  seoDescription:
    'Be Free Studios is a team of designers, engineers, strategists, and advisors. We focus on your vision, develop a future-proof technology strategy, and provide the same quality of service as a forture 100 consulting firm... at a fraction of the cost.',
};
