import { defaultPageProps } from '@/common/props/defaultPage.props';
import { IPageProps } from '@/common/types/page.types';

// @TODO Create SEO Descriptions and tags for each page.

export const DefaultHomePageProps: IPageProps = {
  ...defaultPageProps,
  pageTitle: 'Welcome',
};

export const DefaultAboutPageProps: IPageProps = {
  ...defaultPageProps,
  pageTitle: 'About Us',
  seoTitle: 'ABOUT US | Be Free Studios',
  seoDescription:
    'Founded in 2005, Be Free Studios is a creative web design and development agency based out of the suburbs of Chicago',
  seoKeywords:
    'what about us, about Be Free Studios, about web development, about web design, about us page examples, web designer Chicago, web designer Chicago Suburbs, web developer Chicago, web developer Chicago Suburbs',
};

export const DefaultBlogPageProps: IPageProps = {
  ...defaultPageProps,
  pageTitle: 'Blog',
  seoTitle: 'BLOG | Be Free Studios',
  seoKeywords:
    'front end development blog, modern web development blog, technology blog, web development blog, software development blog, information technology blog, technology blog write for us',
};

export const DefaultServicesPageProps: IPageProps = {
  ...defaultPageProps,
  pageTitle: 'Services',
  seoTitle: 'SERVICES | Be Free Studios',
  seoKeywords:
    'website services for small business, website services for small businesses, website services Chicago, website services Chicago Suburbs, website design services, website design, website development, software development, graphic design, website strategy, SEO Strategy, seo website strategy, seo website services',
};

export const DefaultContactPageProps = {
  ...defaultPageProps,
  pageTitle: 'Contact Us',
  seoTitle: 'CONTACT US | Be Free Studios',
};

export const DefaultNotFoundProps = {
  ...defaultPageProps,
  pageTitle: 'OOPS!',
  seoTitle: '404 | BefreeStudios.dev',
};
