/* eslint-disable react/function-component-definition */
import React from 'react';
import { PageProps } from 'gatsby';
import {
  Container,
  VStack,
  Heading,
  Flex,
  Box,
  Image,
  Button,
} from '@chakra-ui/react';
import { DefaultNotFoundProps } from '@/common/props/page.props';
import { BFS_ClickEvent } from '@/common/types/override.types';
import { IPageProps } from '@/common/types/page.types';
import SEO from '@/components/Seo';

// @TODO Convert to functional component

const NotFound: React.FC<PageProps> = ({
  pageTitle = DefaultNotFoundProps.pageTitle,
  seoTitle = DefaultNotFoundProps.seoTitle,
  seoDescription = DefaultNotFoundProps.seoDescription,
  location,
}: IPageProps) => (
  <Container maxWidth="container.xl">
    <VStack>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname={location.pathname}
      />
      <Heading size="3xl" w="full" textAlign="center">
        {pageTitle}
      </Heading>
      <Heading size="xl" w="full" textAlign="center">
        That Page Does Not Exist
      </Heading>
      <Flex w="full">
        <Image src="https://http.cat/404" marginX="auto" marginTop="32px" />
      </Flex>
    </VStack>
  </Container>
);

NotFound.defaultProps = DefaultNotFoundProps;
export default NotFound;
